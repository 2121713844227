import React from 'react'
import '../DarkMode/DarkMode'

const DarkMode = () => {
  return (
    <>
      {/* <input type="checkbox" id="darkMode" className="dark-mode-checkbox" />
      <label htmlFor="darkMode">
    </label> */}
    </>
  )
}

export default DarkMode
